.appContainer {
  text-align: center;
  justify-content: center;
  position: relative;
  background-color: #ece6dc;
  font-family: 'Noto Sans CJK JP';
  max-width: 435px;
  margin: auto;
  min-height: 100%;
  width: 100%;
}
