@use './styles/font.scss';

@media screen and (max-width: 1280px) {
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 1281px) {
  html {
    font-size: 12px;
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
