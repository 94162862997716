
.logoContainer {
  text-align: center;
}

.linkContainer {
  text-align: center;
  margin-top: 20px;
  p {
    /* テキストリンク */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #1784e9;
  }
}

.answerButtonContainer {
  text-align: center;

  .selectButton {
    font-size: 16px;
    font-family: 'Noto Sans CJK JP';
    width: 343px;
    height: 48px;
    left: calc(50% - 343px / 2 + 343px);
    top: 0px;
    background: #ffffff;
    border-radius: 24px;
    margin: 4px;
    transition: transform 0.1s ease; 
    border: none;

    &:active {
      transform: scale(0.95);
    }
  }

  .selectedButton {
    background-color: #008e7e;
    color: white;
  }
}

.question {
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    margin: 1px;
    color: #000000;
  }
}

.progressContainer {
  position: relative;
  height: 12px;
  background: #f3f3f3;
  border-radius: 10px;
  margin: 20px 12px 20px 12px;
}

.childrenContainer {
  text-align: center;
}

.header {
  h2 {
    position: relative;
    padding: 0 65px;
    font-size: 14px;
    text-align: center;
    margin: 0 16px;
  }

  h2:before {
    position: absolute;
    text-align: center;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: gray;
  }

  h2 span {
    position: relative;
    padding: 0 1em;
    background: #ece6dc;
  }
}
.gradeContainer {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gradeContainer p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  color: #000; 
  font-weight: bold; 
}

.select {
  height: 45px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin: 0;
  width: 70%;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  color: #000; /* セレクトボックスのテキストと矢印の色を黒に */
}

.childDeleteButton {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: none;
  color: black;
  background: transparent;
  text-decoration: underline;
}
.childAddButton {
  cursor: pointer;
  background-color: #444444;
  margin: 20px 20px;
  padding: 10px;
  border-radius: 24px;
  width: 80%;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: white;
  font-family: 'Noto Sans CJK JP';
  transition: transform 0.1s ease; /* アニメーションのためのトランジションを追加 */

  &:active {
    transform: scale(0.95); /* ボタンがアクティブのときにスケールダウン */
  }

  /* 活性化時のスタイル */
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.submitButton {
  
  cursor: pointer;
  width: 90%;
  background-color: #fabf00;
  font-family: 'Noto Sans CJK JP';
  margin: 20px 20px;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  transition: transform 0.1s ease; /* アニメーションのためのトランジションを追加 */

  &:active {
    transform: scale(0.95); /* ボタンがアクティブのときにスケールダウン */
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.progressBar {
  height: 100%;
  background-color: #008e7e;
  border-radius: 10px;
  transition: width 0.3s;
}

.startNumber {
  position: absolute;
  top: 50%; // 縦方向の位置を中央に変更
  left: -8px; // 横方向の位置を中央に変更
  transform: translateY(-50%);
  background-color: #008e7e; 
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  //border: 2px solid #ffffff;
  color: white; 
  z-index: 2; //重なった時はStartが優先
}

.endNumber {
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  //border: 2px solid #ffffff; // 青い境界線を追加
  z-index: 1; //重なった時はStartが優先
}

.questionContainer {
  padding: 5px;
}
