.subMessage {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
}

.button {
  font-size: 14px;
  width: 90%;
  height: 48px;
  margin: auto;
  background: #fabf00;
  border-radius: 24px;
  margin: 4px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  z-index: 0;
  transition: transform 0.1s ease; /* アニメーションのためのトランジションを追加 */

  &:active {
    z-index: 0;
    transform: scale(0.95); /* ボタンがアクティブのときにスケールダウン */
  }
}

.imageWrapper {
  display: flex;
  justify-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.buttonCharImage {
  // 画像の位置の指定方法を変えたい
  position: absolute;
  left: 25px;
  top: -30px;
  z-index: 2;
}

.linkContainer {
  text-align: center;
  p {
    /* テキストリンク */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #1784e9;
  }
}
