.topContainer {
  text-align: center;
  background-color: #fffbd6;
  padding-bottom: 0px;
}

.space {
  margin: 30px auto;
}
.typeContainer {
  text-align: center;
}

.barContainer {
  text-align: center;
  display: flex;
  margin: 14px 18px 0px 18px;
}

.barTitle {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 40px;
}

.barLeft {
  flex: 1;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  height: 20px;
  margin: 0;
  padding: 0;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background-color: #bfbfbf; // デフォルトの灰色
}

.barRight {
  flex: 1;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  height: 20px;
  margin: 0;
  padding: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #bfbfbf; // デフォルトの灰色
}

.barCenter {
  flex: 1;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  height: 20px;
  margin: 0;
  padding: 0;
  background-color: #bfbfbf; // デフォルトの灰色
  border-left: 2px dotted white;
  border-right: 2px dotted white;
}

.gray {
  background-color: #bfbfbf; // デフォルトの灰色
}
.orange {
  background-color: #eb6b43;
}

.green {
  background-color: #008e7e;
}

.blue {
  background-color: #5472da;
}

.main {
  margin: 0;
  font-weight: 700;
  font-size: 26px;
}
.sub {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.typeExplanation {
  padding: 15px;
  display: inline-block;
}

.typeExplanation p {
  background-color: #ffe193;
  padding: 5px 10px;
  margin: 0;
  display: inline;
  font-size: 16px;
  font-weight: 700;
}

.logoContainer {
  text-align: center;
  font-size: 0px;
}

.image {
  margin: 0 auto;
}

.messageBox {
  padding: 20px;
  background-color: #f9f7f3; /* 軽いグレーの背景色 */
  padding: 15px;
  margin: 5px 15px 15px;
  border-radius: 24px;
}

.messageBoxTitle {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.messageBoxSubtitle {
  font-weight: bold;
  color: #008e7e; /* アイコンの色 */
  text-align: center;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 14px;
}

.messageSubBoxContent {
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
  line-height: 150%;
  text-align: center;
  border-radius: 24px;
  margin: 8px 4px;
}

.messageSubBoxTitle {
  text-align: center;
  font-size: 14px;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.messageSubBoxTitleExplanation {
  margin: 8px 8px;
  padding-bottom: 10px;
  padding-top: 0px;
  font-size: 24px;
  font-weight: bold;
  color: #008e7e;
  line-height: 150%;
  vertical-align: middle;
  text-align: center;
}

.messageBoxNumber {
  margin: 8px 2px;
  padding-bottom: 10px;
  padding-top: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #008e7e;
  line-height: 150%;
  vertical-align: middle;
  text-align: center;
}

.messageBoxNumberTitle {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 4px;
  background-color: #008e7e;
  line-height: 150%;
  text-align: center;
  margin: 10px 5px;
  vertical-align: middle;
}

.preMessgaeContainer{
  margin-top:20px;
  margin-left:40px;
  margin-right:0px;
  display:flex;
}

.preMessage::before{
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  left: -15px; /* 三角形の位置を調整 */
  transform: translateY(-50%);
  border-width: 12px;
  border-right: 15px solid #FFFBD6;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.preMessage {
  position: relative;
  background: #FFFBD6;
  border: 2px solid #FFFBD6;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-left:14px;
  margin-right:24px;
  padding: 8px;
  margin-top :10px;
  border-radius: 20px;
  text-align: left;
}

.messageBoxContent {
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
}

.footer {
  margin-top: 50px;
}

.mailForm {
  margin: 0px;
  padding:0px;
}

.mailContainer {
  background-color: #e8f0ee;
  margin-bottom: 0px;
}

.mailHeader{
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 0px;  
  margin-bottom: 20px;  
}

.mailHeaderSeparate{
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin:auto 2px;
}

.mailSubHeader1{
  margin:auto 2px;
  background:linear-gradient(transparent 60%, #ff6 60%);
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}

.mailSubHeader2{
  margin:auto 2px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.imageContainer {
  display: flex;
  flex-direction: column; 
}

.topImages {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px; 
}