.footerWrapper {
  padding: 20px 20px 100px;
  background: #008e7e;
}

.footerTitle {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.footerNav {
  margin-top: 20px;
}

.divider {
  border: 1px solid #34b1a3;
}

.navMenu {
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
}

.navMenuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #43a196;
}

.exportImage {
  width: 10px;
  height: 10px;
  padding: 5px;
}

.copyright {
  text-align: center;
  margin-top: 10px;
  color: white;
  font-size: 10px;
  font-weight: 500;
}
