.header {
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 16px;

    img {
      width: 30%;
      height: auto;
      border-radius: 50%; // イメージを丸く表示
    }
  }
}

.topLogo {
  margin: auto;
  display: block;
  padding: 10px;
}

.logoContainer {
  margin-top: 10px;
}

.messageBar {
  text-align: center;
  background: #008e7e;
  padding: 6px;
  color: white;
  font-weight: 700;
  font-size: 14px;
}

.subTitle {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 4px;
  background: #006b66;
  color: white;
  font-size: 14px;
  position: relative;
  font-weight: 700;
}

.subTitle::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0;
  border-color: #036b67 #ece6dc;
  // cssで無理やりリボン作っているので、親のpaddingを変えるとずれてしまう、、
  border-width: 15px 0px 14px 15px;
  border-style: solid;
  content: '';
}

.subTitle::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0px;
  height: 0;
  border-color: #036b67 #ece6dc;
  border-width: 15px 15px 14px 0px;
  border-style: solid;
  content: '';
}

.image {
  width: 100%;
  height: auto;
}

.imagesWrapper {
  width: 30%;
  height: auto;
  padding: 5px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.imageBlock {
  background: #f4f2ee;
}
