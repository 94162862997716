.loadingContainer {
  height: 100vh;
  z-index: 99999999999999;
  background-color: #ece6dc;
}

.logoImageContainer {
  margin-top: 30px;
  text-align: center;
}

.message {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.questionHeaderContainer {
  width: 100%;
  text-align: center;
}
