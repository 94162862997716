
.logoContainer {
	margin: 30px auto;
	text-align: center;
	transform: scale(1.5);
}


.completeTitle{
	text-align: center;
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;
	color: #000000;
}

.completeMessage{
	text-align: left;
	margin:30px 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: #000000;
}

.completeMessageWaring{
	text-align: left;
	margin:20px 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: red;
	margin-bottom: 50px;
}
.completeMessageWaringTitle{
	text-align: center;
	margin:20px 10px;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	color: red;
}


